<template>
  <div class="main-content">
    <page-title name="Проекты" route_name="projects"/>
    <div class="section">
      <div class="row">
        <div :key="index" class="col-one-third" v-for="(projects, index) in items.data">
          <article class="post post-tp-8">
            <figure v-if="projects.img_articles.length">
              <router-link :to="{name: 'open_project', params: {id: projects.id}}">
                <img :alt="projects.name" :src="projects.img_articles[0].get_img.media_server_url + projects.img_articles[0].get_img.url" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;">
              </router-link>
            </figure>
            <figure v-else>
              <router-link :to="{name: 'open_project', params: {id: projects.id}}">
                <img :alt="projects.name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;">
              </router-link>
            </figure>
            <h3 class="title-5">
              <router-link :to="{name: 'open_project', params: {id: projects.id}}">{{ projects.name }}</router-link>
            </h3>
            <div class="meta-tp-2">
              <div class="date"><span>{{ projects.updated_at | human_date}}</span></div>
            </div>
          </article>
        </div>
      </div>
      <div class="page-nav" style="text-align: center;" v-if="items.total > items.per_page">
        <a @click.prevent="switchPage(items.next_page_url)" class="btn-2" href="" v-if="items.next_page_url !== null">Загрузить еще</a>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/layouts/PageTitle.vue';

export default {
  name: 'Projects',
  components: { PageTitle },
  async asyncData (context) {
    await context.store.dispatch('projectsListPage');
  },
  beforeCreate () {
    this.items = this.$store.state.projects;
  },
  methods: {
    /**
     * Пагинация
     * @param url - ссылка из пагинации laravel
     */
    async switchPage (url) {
      if (url !== null) {
        this.$store.state.system_data.loading = true;
        await this.$store.dispatch('projectsListPage', url);
        this.items.data.push(...new Set(this.$store.state.projects.data));
        this.items.next_page_url = this.$store.state.projects.next_page_url;
        this.$store.state.system_data.loading = false;
      }
    },
  },
  metaInfo () {
    return this.$seo('Проекты');
  }
};
</script>
